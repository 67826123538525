import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
        <h4 className={classes.description}>
                  Notre démarche et de proposer des produits complets dotés d'interfaces intituitives 
                  complétées d{"'"}une grande qualité graphique.                  
              </h4>
          <h2 className={classes.title}>Pour nous contacter</h2>
          <h4 className={classes.description}>
          ARTWEB-PRODUCTION 10 Parc Club du Millénaire
          <br />
1025 av Henri Becquerel 34000 Montpellier
<br />
RCS 494 256 100 | +33 6 34 45 93 47
</h4>
<h4 className={classes.description}>
Artweb-Production, société à responsabilité limitée est active depuis 2007,
<br /> spécialisée dans le conseil et développement de systèmes et logiciels informatiques.
            {/* Vous pouvez nous décrire rapidement votre projet ou demander un rdv téléphonique.
            <br />
            Nous vous répondrons dans les plus brefs délais. */}
          </h4>
          {/* <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Votre Nom"
                  id="name" 
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Votre E-mail"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <CustomInput
                labelText="Votre Message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea,
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                }}
              />
              <GridItem xs={12} sm={12} md={4}>
                <Button color="primary">Envoyer votre Message</Button>
              </GridItem>
            </GridContainer>
          </form> */}
        </GridItem>
      </GridContainer>
    </div>
  );
}
