import React from "react";

// import Reactstate, { useState } from 'react';

import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/david.jpg";

import studio1 from "assets/img/logo/flex_square.png";
import studio2 from "assets/img/logo/eflex_square.png";
import studio3 from "assets/img/logo/inrealtime_square.png";
import studio4 from "assets/img/logo/sweetyform_square.png";
// import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/FLEX.jpg";
import work2 from "assets/img/EFLEX.jpg";
import work3 from "assets/img/INREALTIME.jpg";
// import work4 from "assets/img/OUIMEAT.jpg";
import work5 from "assets/img/SWEETYFORM.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);
// const testeur = React.useState(null);
export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgRaised, classes.imgFluid, classes.imgGallery);
  const navImageOtherClasses = classNames(classes.imgRounded, classes.imgRaised, classes.imgReduct, classes.imgGallery);

  return (
    <div>
      <Header
        color="transparent"
        brand=""
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg.jpg").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>DAVID</h3>
                    <h6>WEB DESIGNER - CONCEPTEUR - INTEGRATEUR depuis 2001</h6>
                    {/* <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-twitter"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button> */}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                Développement environnement Web pour la gestion de l'inventaire des grandes entreprises.</p>
              <p>Développement e-commerce, webshop, marketplace avec modules de paiement en ligne securisés.</p>
              <p>Création de Site Web, conception et design.</p>
              <p>Hébergement sur serveur dédié haute disponibilité.</p>
              <p>Administration réseaux, suivi et maintenance.</p>
              <p>Intégration Smartphone application sur Play Store et Apple Store.</p>
              <p>SEO, Adwords.</p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={10} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "REALISATIONS",
                      tabIcon: Palette,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={6}>
                            <h3>FLEX</h3>
                            <p>GESTION DE VOS MOBILIERS ET EQUIPEMENTS</p>
                            <p>Inventaire de mobilier et d'équipement, développé en partenariat
                              avec Le Majordome des Lieux de Vie depuis 2006.</p>
                              <a href="https://www.flex.services"
                            target="_blank"
                            >
                            <img
                              alt="..."
                              src={work1}
                              className={navImageClasses}
                            />
                            </a>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <h3>eFLEX</h3>
                            <p>PORTAIL DE VENTE EN LIGNE</p>
                            <p>Achetez vos mobiliers et équipements neufs ou recyclés sur eFLEX.<br /><br /></p>
                            {/* <p>&nbsp;</p> */}
                            <a href="https://eflex.lemajordome.fr"
                            target="_blank"
                            >
                            <img
                              alt="..."
                              src={work2}
                              className={navImageClasses}
                            />
                            </a>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <h3>inREALTIME</h3>
                            <p>CREER VOTRE BOUTIQUE EN LIGNE</p>
                            <p>Créez facilement votre catalogue et vos fiches produits, vendez sur Internet avec notre solution e-Commerce.</p>
                            <a href="https://www.inrealtime.fr"
                            target="_blank"
                            >
                            <img
                              alt="..."
                              src={work3}
                              className={navImageClasses}
                            />
                            </a>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <h3>SWEETYFORM</h3>
                            <p>COACHING EN CHRONO-NUTRITION®</p>
                            <p>Encadré par une équipe de professionnels, qui vous accompagne de façon bienveillante dans votre Rééquilibrage Alimentaire.<br /></p>
                            <a href="https://www.sweetyform.com"
                            target="_blank"
                            >
                              <img
                              alt="..."
                              src={work5}
                              className={navImageClasses}
                            />
                            </a>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: "Informations",
                      tabIcon: Camera,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                          <h4>FLEX</h4>
                            <p>LA SOLUTION POUR LA GESTION DE VOS MOBILIERS ET EQUIPEMENTS</p>
                            <p>Clients grands comptes - Inventaire - RFID - Code-barres - QRCODE</p>
                            <img
                              alt="..."
                              src={studio1}
                              className={navImageOtherClasses}
                            />
                            <h4>inREALTIME</h4>
                            <p>CREER VOTRE BOUTIQUE EN LIGNE.</p>
                            <p>Vendez sur Internet avec notre solution e-Commerce et votre nom de domaine, connectez-vous avec vos clients.<br /><br /></p>
                            <img
                              alt="..."
                              src={studio3}
                              className={navImageOtherClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                          <h4>eFLEX</h4>
                            <p>LA SOLUTION DE VENTE EN LIGNE DU  MAJORDOME DES LIEUX DE VIE</p>
                            <p>Achetez vos mobiliers et équipements neufs ou recyclés</p>
                            <img
                              alt="..."
                              src={studio2}
                              className={navImageOtherClasses}
                            />
                             <h4>SWEETYFORM</h4>
                             <p>COACHING EN CHRONO-NUTRITION®</p>
                             <p>Des Professionels nutritionistes vous accompagnent en vous proposant différents abonnements selon vos besoins,
                            coaching et encadrement pour améliorer votre santé.</p>
                            <img
                              alt="..."
                              src={studio4}
                              className={navImageOtherClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      ),
                    },                    
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
